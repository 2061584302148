@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      @apply scroll-smooth;
    }
  }


html {
  overscroll-behavior-y: none;
}

body {
  overscroll-behavior-y: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}